<template lang="pug">
v-menu(v-model="menuShowing" :close-on-content-click="false")
  template(v-slot:activator="{ on }")
    v-icon(v-on="on" small).copy-arrow mdi-menu-down
  v-card.select-menu
    v-card-text
      v-text-field(
        v-model="text"
        :label="label"
      )
    v-divider
    v-card-actions
      v-spacer
      v-btn(color="primary" outlined small @click="close") cancel
      v-btn(color="primary" small @click="submit") ok
</template>

<script>
export default {
  props: {
    label: String,
    value: String
  },

  data: () => ({
    menuShowing : false,

    text: ''
  }),

  mounted() {
  },

  methods: {
    submit() {
      this.close()
      this.$emit('change', this.text)
    },

    open() {
      this.menuShowing = true
    },

    close() {
      this.menuShowing = false
    }
    
  },

  watch: {
    menuShowing(isShowing) {
      if(isShowing) this.text = this.value
    }
  }
}
</script>

<style lang="scss">
.select-menu {
  max-height: 300px;
  overflow: auto;
}

.v-list .v-list-item.active {
  font-weight: bold;
}
</style>